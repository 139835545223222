import React, {useEffect, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import {Button, Grid, Paper, Checkbox, OutlinedInput, TextField, IconButton} from "@mui/material";
import {
    deleteSaleApi,
    deleteSalesApi,
    markAsNotifiedApi,
    showSalesApi,
    exportSalesApi, apiGetPDFSale
} from "../../../../services/Admin/sale";
import {Delete, Edit} from "@mui/icons-material";
import PopupDelete from "../../../../components/PopupDelete";
import MsgSuccess from "../../../../components/MsgSuccess";
import MsgError from "../../../../components/MsgError";
import InputSelect2 from "../../../../components/Inputs/InputSelect2";
import InputText from "../../../../components/Inputs/InputText";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import {Link} from "react-router-dom";
import {DesktopDatePicker, LoadingButton, LocalizationProvider} from "@mui/lab";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import Table from "../../../../components/Table";
import downloaderFile from "../../../../utils/downloaderFile";

export default function Management() {
    const [sales, setSales] = useState([])
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [state, setState] = useState(initialValues)
    const [quantity, setQuantity] = useState(0)
    const {logout} = useAuth()
    const [selection, setSelection] = useState([])
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
        message: null
    });


    useEffect(() => {
        setSales([])
        const timeOutId = setTimeout(() => {
            if (state.year && state.quarter) {
                getSalesData()
            }
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const timestapConvert = (timestamp) => {
        // Convertir el timestamp a una fecha
        const fecha = new Date(timestamp * 1000)
        // Establecer las horas, minutos, segundos y milisegundos a cero
        fecha.setHours(0)
        fecha.setMinutes(0)
        fecha.setSeconds(0)
        fecha.setMilliseconds(0)

        // Convertir la fecha a un timestamp Unix sin horas ni minutos
        return moment(fecha).format("X")
    }

    function getSalesData() {
        setLoading(true);
        setMsgError([])
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            nif: "",
            clientCode: "",
            year: "",
            quarter: ""
        }

        if (state.nif) {
            params.nif = state.nif
        }
        if (state.clientCode) {
            params.clientCode = state.clientCode
        }
        if (state.year) {
            params.year = state.year
        }
        if (state.quarter) {
            params.quarter = state.quarter
        }
        if (state.isNotified) {
            params.isNotified = state.isNotified
        }
        if (state.isNotifiedAPI) {
            params.isNotifiedAPI = state.isNotifiedAPI
        }

        // Convert notificationDate to timestamp
        if (state.notificationDate) {
            params.notificationDate = timestapConvert(moment(state.notificationDate).format("X"))

        }

        showSalesApi(logout, params)
            .then(data => {
                let position = {}

                data.data.forEach((doc, key) => {
                    position[`${key}`] = false
                })

                setSalesData(data.data)
                setQuantity(data.count)
                setLoading(false)
            })
            .catch(error => {
                setMsgError([error?.response?.data.message])
                setLoading(false)
                setSales([])
            });
    }

    function setSalesData(snapshot) {

        const temp = snapshot.map((doc) => {
            const href = `/admin/sales/edit/${doc.id}`

            return {
                organizationName: doc?.organization?.name,
                organizationNif: (<a href={href}>{doc?.organization?.nif}</a>),
                organizationClientCode: doc?.organization?.clientCode,
                organizationNameChinese: doc?.organization?.nameChinese,
                notified: (doc?.isNotified == 1) ? "Si" : "No",
                actions: (
                    <Grid container>
                        <Grid item xs={6}>
                            <Button variant="text" color="primary" href={href} target={"_blank"}><Edit/></Button>
                        </Grid>
                        {!doc.deleted && (
                            <Grid item xs={6}>
                                <Button variant="text" onClick={() => {
                                    handleDelete(doc.id)
                                }}><Delete/></Button>
                            </Grid>
                        )}

                    </Grid>
                ),
                ...doc,
            }
        })
        setSales(temp)
        setLoading(false)
    }

    const handleDelete = (id) => {
        setPopup({
            show: true,
            id,
        });
    };

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {

            const idx = popup.id


            const request = (typeof idx === "object") ? deleteSalesApi(logout, popup.id) : deleteSaleApi(logout, popup.id)

            request.then(response => {
                setMsgSuccess(response.data)
                getSalesData()
                setSelection([])
            }).catch(error => {
                setMsgError([error.response.data.errors])
            })
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };

    const handleBulkDelete = () => {

        setPopup({
            show: true,
            id: selection,
            message: "¿Estás seguro de querer eliminar estos registros?"
        })

    }

    const exportData = () => {
        setLoadingExport(true)
        exportSalesApi(logout, {
            "nif": state.nif,
            "clientCode": state.clientCode,
            "year": state.year,
            "quarter": state.quarter,
            "model": "303"
        }).then(response => {
            window.open(response.data, '_blank');
            setLoadingExport(false)
        }).catch(error => {
            setMsgError([error.response.data.errors])
            setLoadingExport(false)
        })

    }

    const handleMarkAsNotified = () => {

        markAsNotifiedApi(logout, selection).then(response => {
            setMsgSuccess(response.data)
            getSalesData()
        }).catch(error => {
            setMsgError([error.response.data.errors])
        })

    }
    const handleChangeSelection = (e) => {
        let value = parseInt(e.target.value)
        if (selection.includes(value)) {
            setSelection(selection.filter(item => item !== value))
        } else {
            setSelection([...selection, value])
        }

    }
    const handleMarkAsPdf = () => {
        setMsgError([])
        setLoadingPdf(true)
        apiGetPDFSale({
            selection
        }).then(response => {
            let url = response.data.url
            let name = url.split('/').pop()
            downloaderFile(url, name)
            setLoadingPdf(false)
        }).catch(error => {
            setMsgError([error.response.data.errors])
            setLoadingPdf(false)
        })
    }

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        setState({...state})
    }

    return (
        <>
            {popup.show && (
                <PopupDelete
                    show={popup.show}
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                    message={popup.message}
                />
            )}
            <Grid container xs={12} spacing={3}>
                <Grid item xs={3}>
                    <h1 style={{marginLeft: 25}}>Ventas 303</h1>
                </Grid>
                <Grid item xs={3} style={{margin: "25px 0px"}}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        href="/admin/sales/import">
                        Importar
                    </Button>
                </Grid>
                <Grid item xs={3} style={{margin: "25px 0px"}}>
                    <LoadingButton
                        type="button"
                        color="primary"
                        loading={loadingExport}
                        loadingPosition="start"
                        variant="outlined"
                        fullWidth
                        onClick={exportData}
                    >
                        Exportar
                    </LoadingButton>
                </Grid>
                <Grid item xs={3} style={{margin: "25px 0px"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        href="/admin/sales/create">
                        + Añadir
                    </Button>
                </Grid>
                {(selection.length > 0 || state.checkAll) && (
                    <>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3} style={{paddingTop: 0}}>
                            <LoadingButton
                                type="button"
                                loading={loadingPdf}
                                loadingPosition="start"
                                variant="outlined"
                                onClick={handleMarkAsPdf}
                                fullWidth
                            >
                                Obtener PDF
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={3} style={{paddingTop: 0}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleBulkDelete}>
                                Eliminar
                            </Button>
                        </Grid>
                        <Grid item xs={3} style={{paddingTop: 0}}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleMarkAsNotified}>
                                Notificar
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>


            <Grid container spacing={1}>
                <Grid item xs={12}>


                    <Paper sx={{p: 2, m: 3, display: 'flex', flexDirection: 'column'}}>

                        <MsgSuccess msgSuccess={msgSuccess}/>
                        <MsgError msgError={msgError}/>

                        <Filters
                            state={state}
                            setState={setState}
                            setSelection={setSelection}
                            sales={sales}
                        />

                        {/*<OwsTable*/}
                        {/*    columns={tableColumns(handleChangeSelection, handleDelete, selection, state)}*/}
                        {/*    rows={sales}*/}
                        {/*    rowCount={quantity}*/}
                        {/*    filter={state}*/}
                        {/*    setFilter={setState}*/}
                        {/*    loading={loading}*/}
                        {/*/>*/}

                        <Table
                            columns={tableColumns(handleChangeSelection, handleDelete, selection, state)}
                            rows={sales}
                            rowCount={quantity}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
        clientCode: "",
        nif: "",
        checkAll: false,
        notificationDate: null,
        isNotified: false,
        isNotifiedAPI: false,
    }
}

function tableColumns(handleChangeSelection, handleDelete, selection, state) {
    return [
        {
            field: "checkbox",
            headerName: "",
            sortable: false,
            renderCell: (params) => {
                let checked = !state.checkAll ? selection.includes(parseInt(params.id)) : true
                return (
                    <>
                        <Checkbox onChange={handleChangeSelection} value={params.id} checked={checked}/>
                    </>
                )
            },
        },
        {
            headerName: "COD. CLIENTE",
            field: "organizationClientCode",
            width: 100,
            sortable: true,
        },
        {
            field: 'NIF',
            headerName: 'NIF',
            width: 100,
            sortable: true,
            renderCell: (params) => {
                return <Link
                    to={`/admin/organizations/edit/${params.organizationId}`} target="_blank" rel="noopener noreferrer">{params.organizationNif} </Link>;
            },
        },
        {
            headerName: 'PDF',
            field: 'urlPdfAeat',
            sortable: true,
            width: 100,
            renderCell: (params) => {
                return <a href={params.urlPdfAeat} target={"_blank"} ><PictureAsPdfIcon/></a>;
            },
        },
        {
            headerName: "ORGANIZACION",
            field: "organizationName",
            sortable: true,
            width: 400
        },
        {
            headerName: "ORGANIZACION CHINO",
            field: "organizationNameChinese",
            sortable: true,
            width: 400
        },
        {
            headerName: "AÑO",
            field: "year",
            sortable: true,
            width: 100,
        },
        {
            headerName: "TRIMESTRE",
            field: "quarter",
            sortable: true,
            width: 100,
        },

        {
            headerName: "IVA TRIMESTRE ANTERIOR",
            field: "previousQuarter",
            sortable: true,
            width: 200
        },
        {
            headerName: "COMUNICADA 303",
            field: "notified",
            sortable: true,
            width: 100,
        },
        {
            headerName: "ACCIONES",
            field: "actions",
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/sales/edit/${params.id}`} target="_blank" rel="noopener noreferrer">
                            <IconButton size="small">
                                <Edit fontSize="inherit" />
                            </IconButton>
                        </Link>
                        {!params.deleted && (
                            <IconButton
                                variant="text"
                                onClick={() => {handleDelete(params.id)}}
                                size="small"
                            >
                                <Delete fontSize="inherit"/>
                            </IconButton>
                        )}
                    </>
                )
            },

        }

    ]
}

function Filters(props) {

    const {state, setState, setSelection, sales} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const handleChangeCheckAll = (event) => {
        let tempSelection = []
        state[event.target.name] = event.target.checked
        if (!event.target.checked) {
            setSelection([])
        }else{
            sales.forEach(item => {
                item.checked = true
                tempSelection.push(item.id)
            })
            setSelection(tempSelection)
        }
        setState({...state})
    }
    const curYear = new Date().getFullYear()
    let optionsSaleYear = []
    for (let i = 2022; i <= curYear; i++) {
        optionsSaleYear.push({id: i, name: i})
    }
    const optionsSaleQuarter = [{id: "1T", name: "Primer trimestre"}, {id: "2T", name: "Segundo trimestre"}, {
        id: "3T",
        name: "Tercer trimestre"
    }, {id: "4T", name: "Cuarto trimestre"}]
    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={3}>
                <InputSelect2
                    label={"Año"}
                    name="year"
                    onChange={handleChange}
                    value={state?.year}
                    options={optionsSaleYear}>
                </InputSelect2>
            </Grid>
            <Grid item xs={3}>
                <InputSelect2
                    label={"Trimestre"}
                    name="quarter"
                    onChange={handleChange}
                    value={state?.quarter}
                    options={optionsSaleQuarter}>
                </InputSelect2>
            </Grid>
            <Grid item xs={3}>
                <InputText
                    label="Código cliente"
                    name="clientCode"
                    value={state.clientCode}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={3}>
                <InputText
                    label="NIF"
                    name="nif"
                    onChange={handleChange}
                    value={state.nif}
                />
            </Grid>

            <Grid item xs={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                        label="Fecha de Presentación"
                        name="notificationDate"
                        value={state?.notificationDate}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                            state.notificationDate = newValue
                            setState({...state})
                        }}
                        renderInput={(params) => <TextField size="small" {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={3}>
                <Checkbox name="isNotified" onChange={handleChangeCheckAll} checked={state.isNotified}/>
                Notificado
            </Grid>
            <Grid item xs={3}>
                <Checkbox name="isNotifiedAPI" onChange={handleChangeCheckAll} checked={state.isNotifiedAPI}/>
                Notificado via API
            </Grid>
            <Grid item xs={3}>
                <Checkbox name="checkAll" onChange={handleChangeCheckAll} checked={state.checkAll}/>
                Marcar todos los registros
            </Grid>
        </Grid>
    )
}

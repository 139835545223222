import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {Delete, DownloadFile, Get, Post, Put} from "../../utils/request";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}`;
const token = `Bearer ${getToken()}`;

export async function createApi(data) {
    const config = {
        headers: {
            "Authorization": `${token}`,
        }
    }

    return axios.post(`${baseUrl}/sale/create`, data, config)
}

export async function create349Api(data) {
    const config = {
        headers: {
            "Authorization": `${token}`,
        }
    }

    return axios.post(`${baseUrl}/sale349/create`, data, config)
}
export async function showSalesApi(logout, params) {
    let url = `${baseUrl}/sale/list` ;

    const ret = await Get(url, logout, `Bearer ${getToken()}`, params);
    return ret;
}

export async function showSales349Api(logout, params) {
    let url = `${baseUrl}/sale349/list` ;

    const ret = await Get(url, logout, `Bearer ${getToken()}`, params);
    return ret;
}

export async function showSales390Api(logout, params) {
    let url = `${baseUrl}/sale390/list` ;

    const ret = await Get(url, logout, `Bearer ${getToken()}`, params);
    return ret;
}

export async function getSalesApi(logout, id) {
    const url = `${baseUrl}/sale/${id}`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function getSales349Api(logout, id) {
    const url = `${baseUrl}/sale349/${id}`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function getSales390Api(logout, id) {
    const url = `${baseUrl}/sale390/${id}`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function updateSalesApi(logout, data) {
    const url = `${baseUrl}/sale/update`;
    const ret = await Put(url, logout, data, token);
    return ret;
}

export async function updateSales349Api(logout, data) {
    const url = `${baseUrl}/sale349/update`;
    const ret = await Put(url, logout, data, token);
    return ret;
}

export async function updateSales390Api(logout, data) {
    const url = `${baseUrl}/sale390/update`;
    const ret = await Put(url, logout, data, token);
    return ret;
}

export async function importSalesApi(logout, data) {
    const url = `${baseUrl}/sale/import-excel`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function showImportSalesApi(logout) {
    const url = `${baseUrl}/sale/list-import`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function exportSalesApi(logout, data) {
    const url = `${baseUrl}/sale/export-excel`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function loadImportSalesApi(logout) {
    const url = `${baseUrl}/sale/data-import`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function showSalesModelApi(logout, data) {
    const url = `${baseUrl}/sale/model-preview`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function downloadModelApi(logout, data) {
    console.log(data)
    const url = `${baseUrl}/sale/model-download`;
    return await DownloadFile(url, logout, data, token);
}

export async function setProcessingSalesApi(logout, data) {
    const url = `${baseUrl}/sale/set-processing-sales`;
    return await Post(url, logout, data, token);
}

export async function getProcessingSalesApi(id) {
    const config = {
        headers: {
            "Authorization": `${token}`,
        }
    }
    return axios.get(`${baseUrl}/sale/get-processing-sales/${id}`, config)
}

export async function sendModelApi(data) {
    const config = {
        headers: {
            "Authorization": `${token}`,
        }
    }

    return axios.post(`${baseUrl}/sale/model-send`, data, config)
}

export async function deleteSaleApi(logout, id) {
    const url = `${baseUrl}/sale/delete/${id}`;
    const ret = await Delete(url, logout, null, token);
    return ret;
}

export async function deleteSalesApi(logout, ids) {
    const url = `${baseUrl}/sale/delete`;
    const ret = await Delete(url, logout, ids, token);
    return ret;
}

export async function deleteSale349Api(logout, id) {
    const url = `${baseUrl}/sale349/delete/${id}`;
    const ret = await Delete(url, logout, null, token);
    return ret;
}

export async function deleteSales349Api(logout, ids) {
    const url = `${baseUrl}/sale349/delete`;
    const ret = await Delete(url, logout, ids, token);
    return ret;
}

export async function deleteSale390Api(logout, id) {
    const url = `${baseUrl}/sale390/delete/${id}`;
    const ret = await Delete(url, logout, null, token);
    return ret;
}

export async function deleteSales390Api(logout, ids) {
    const url = `${baseUrl}/sale390/delete`;
    const ret = await Delete(url, logout, ids, token);
    return ret;
}

export async function markAsNotifiedApi(logout, ids) {
    const url = `${baseUrl}/sale/notify`;
    const ret = await Put(url, logout, ids, token);
    return ret;
}

export async function checkVatVies(logout, data) {
    const url = `${baseUrl}/sale/vat-verification`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function importSales349Api(logout, data) {
    const url = `${baseUrl}/sale/import-excel-349`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function importSales390Api(logout, data) {
    const url = `${baseUrl}/sale/import-excel-390`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function getOrganizations390Api(logout, data) {
    const url = `${baseUrl}/sale/get-organizations-390`;
    const ret = await Post(url, logout, data, token);
    return ret;
}

export async function loadImportSales349Api(logout) {
    const url = `${baseUrl}/sale/list-import-349`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function loadImportSales390Api(logout) {
    const url = `${baseUrl}/sale/list-import-390`;
    const ret = await Get(url, logout, token);
    return ret;
}

export async function apiGetPDFSale(props) {
    const config = {headers: {'Authorization': `Bearer ${getToken()}`}};

    let query = "";
    props.selection.forEach((item) => {
        query += `&id[]=${item}`
    })

    return axios.get(`${baseUrl}/sale/get-pdf-sale?1=1${query}`, config)
}

import React, {useEffect, useState} from "react";
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {
    Cached,
    CorporateFare,
    ExpandLess,
    ExpandMore,
    ListAlt,
    MonetizationOn,
    People,
    Upload,
    Dashboard
} from "@mui/icons-material";
import useAuth from "../../../hooks/useAuth";

export default function MenuPrimary(props) {
    const {menu} = props;

    const {auth} = useAuth()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [classMenu, setClassMenu] = useState({
        users: false,
    });
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleClick2 = () => {
        setOpen2(!open2);
    }
    const handleClick3 = () => {
        setOpen3(!open3);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        switch (menu) {
            case "dashboard":
                setClassMenu({...classMenu, dashboard: true});
                break;
            case "users":
                setClassMenu({...classMenu, users: true});
                break;
            case "archived-organizations":
                setOpen3(true);
                setClassMenu({...classMenu, archived: true});
                break;
            case "no-archived-organizations":
                setOpen3(true);
                setClassMenu({...classMenu, noArchived: true});
                break;
            case "epigrafes":
                setClassMenu({...classMenu, epigrafes: true});
                break;
            case "sales-model-303":
                setOpen2(true);
                setClassMenu({...classMenu, salesModel303: true});
                break;
            case "sales-model-349":
                setOpen2(true);
                setClassMenu({...classMenu, salesModel349: true});
                break;
            case "sales-model-390":
                setOpen2(true);
                setClassMenu({...classMenu, salesModel390: true});
                break;
            case "organizations-import":
                setOpen(true);
                setClassMenu({...classMenu, organizationsImport: true});
                break;
            case "sales-import":
                setOpen(true);
                setClassMenu({...classMenu, salesImport: true});
                break;
            case "model-generate":
                setClassMenu({...classMenu, modelGenerate: true});
                break;
            default:
                setClassMenu({...classMenu});
                break;
        }
    }, [menu]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <List component="nav">
            {["ROLE_ADMINISTRATOR"].includes(auth?.role) && (
                <ListItemButton
                    component="a"
                    href="/admin/dashboard/management"
                    selected={classMenu.dashboard}
                >
                    <ListItemIcon>
                        <Dashboard/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard"/>
                </ListItemButton>
            )}

            {["ROLE_ADMINISTRATOR"].includes(auth?.role) && (
                <ListItemButton
                    component="a"
                    href="/admin/users/management"
                    selected={classMenu.users}
                >
                    <ListItemIcon>
                        <People/>
                    </ListItemIcon>
                    <ListItemText primary="Usuarios"/>
                </ListItemButton>
            )}

            <ListItemButton
                onClick={handleClick3}
            >
                <ListItemIcon>
                    <CorporateFare/>
                </ListItemIcon>
                <ListItemText primary="Organizaciones"/>
                {open3 ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>

            <Collapse in={open3} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{pl: 8}}
                        component="a"
                        href="/admin/organizations/management?archived=0"
                        selected={classMenu.noArchived}>
                        <ListItemText primary="Activas"/>
                    </ListItemButton>
                    <ListItemButton
                        sx={{pl: 8}}
                        component="a"
                        href="/admin/organizations/management?archived=1"
                        selected={classMenu.archived}>
                        <ListItemText primary="Archivadas"/>
                    </ListItemButton>
                </List>
            </Collapse>

            <ListItemButton
                button
                component="a"
                href="/admin/epigrafes/management"
                selected={classMenu.epigrafes}
            >
                <ListItemIcon>
                    <ListAlt/>
                </ListItemIcon>
                <ListItemText primary="Epigrafes"/>
            </ListItemButton>

            <ListItemButton
                onClick={handleClick2}
            >
                <ListItemIcon>
                    <MonetizationOn/>
                </ListItemIcon>
                <ListItemText primary="Ventas"/>
                {open2 ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>

            <Collapse in={open2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{pl: 8}}
                        component="a"
                        href="/admin/sales/management"
                        selected={classMenu.salesModel303}>
                        <ListItemText primary="303"/>
                    </ListItemButton>
                    <ListItemButton
                        sx={{pl: 8}}
                        component="a"
                        href="/admin/sales349/management"
                        selected={classMenu.salesModel349}>
                        <ListItemText primary="349"/>
                    </ListItemButton>
                    <ListItemButton
                        sx={{pl: 8}}
                        component="a"
                        href="/admin/sales390/management"
                        selected={classMenu.salesModel390}>
                        <ListItemText primary="390"/>
                    </ListItemButton>
                </List>
            </Collapse>

            <ListItemButton
                onClick={handleClick}
            >
                <ListItemIcon>
                    <Upload/>
                </ListItemIcon>
                <ListItemText primary="Importación"/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton
                        sx={{pl: 8}}
                        component="a"
                        href="/admin/organizations/import"
                        selected={classMenu.organizationsImport}>
                        <ListItemText primary="Organizaciones"/>
                    </ListItemButton>
                    <ListItemButton
                        sx={{pl: 8}}
                        component="a"
                        href="/admin/sales/import"
                        selected={classMenu.salesImport}>
                        <ListItemText primary="Ventas"/>
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton
                button
                component="a"
                href="/admin/models/generate"
                selected={classMenu.modelGenerate}
            >
                <ListItemIcon>
                    <Cached/>
                </ListItemIcon>
                <ListItemText primary="Generar Modelos"/>
            </ListItemButton>
        </List>
    );
}

import React, {useEffect, useMemo, useRef, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableFilter from "../../../../components/Admin/TableFilter";
import {Button, Grid, Link, Paper} from "@mui/material";
import {
    checkVatVies,
    downloadModelApi,
    sendModelApi,
    showSalesModelApi,
    importSales349Api,
    getOrganizations390Api,
    setProcessingSalesApi,
    getProcessingSalesApi
} from "../../../../services/Admin/sale";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import LoadingProgress from "../../../../components/LoadingProgress";
import {SelectColumnFilter} from "../../../../components/TableComponents/SelectColumnFilter";
import VerticalWizard from "../../../../components/Admin/VerticalWizard";
import InputFile from "../../../../components/Inputs/InputFile";
import {LoadingButton} from "@mui/lab";
import downloaderFile from "../../../../utils/downloaderFile";
import clone from "../../../../utils/clone";
import ModalProcessing from "./ModalProcessing";


export default function Import() {
    const [sales, setSales] = useState([])
    const {logout} = useAuth()
    const [state, setState] = useState(initialValues())
    const [preview, setPreview] = useState(false)
    const [showButtonPreview, setShowButtonPreview] = useState(false)
    const [showTable303, setShowTable303] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [idProcessing, setIdProcessing] = useState(null)

    const curYear = new Date().getFullYear()
    let optionsSaleYear = []
    for (let i = 2022; i <= curYear; i++) {
        optionsSaleYear.push({[i]: i})
    }
    const optionsModel = [{"303": 303}, {"349": 349}, {"390": 390}]
    const optionsSaleQuarter = [{"1T": "Primer trimestre"}, {"2T": "Segundo trimestre"}, {"3T": "Tercer trimestre"}, {"4T": "Cuarto trimestre"}]

    //  349
    const [data349, setData349] = useState([])
    const [enableNextImport349, setEnableNextImport349] = useState(false)
    const [enableNextVies, setEnableNextVies] = useState(false)
    const [enableFinish, setEnableFinish] = useState(false)
    const [show349, setShow349] = useState(false)
    const [showButton349, setShowButton349] = useState(false)
    const [file, setFile] = useState(null)
    const form = useRef()

    //  390
    const [showButton390, setShowButton390] = useState(false)
    const [show390, setShow390] = useState(false)
    const [enableNextImport390, setEnableNextImport390] = useState(false)

    // ERRORS
    const [msgError, setMsgError] = useState([])
    const [msgErrorGenerate390, setMsgErrorGenerate390] = useState([])
    const [msgErrorImport390, setMsgErrorImport390] = useState([])
    const [msgErrorGenerate349, setMsgErrorGenerate349] = useState([])
    const [msgErrorImport349, setMsgErrorImport349] = useState([])
    const [msgErrorVies, setMsgErrorVies] = useState([])

    // SUCCESS
    const [msgSuccess, setMsgSuccess] = useState([])
    const [msgSuccessImport349, setMsgSuccessImport349] = useState([])
    const [msgSuccessVies, setMsgSuccessVies] = useState([])
    const [msgSuccessGenerate390, setMsgSuccessGenerate390] = useState([])
    const [msgSuccessGenerate349, setMsgSuccessGenerate349] = useState([])
    const [msgSuccessImport390, setMsgSuccessImport390] = useState([])

    // LOADING
    const [loading, setLoading] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [loadingBtnNoVerify, setLoadingBtnNoVerify] = useState(false)
    const [loadingBtnSend, setLoadingBtnSend] = useState(false)
    const [loadingProgress, setLoadingProgress] = useState(false)
    const [time, setTime] = useState(false)
    const [solicitudPorcentaje, setSolicitudPorcentaje] = useState(0)
    const [modalProcessing, setModalProcessing] = useState(false)

    const restartState = () => {
        // ERRORS
        setMsgError([])
        setMsgErrorGenerate390([])
        setMsgErrorImport390([])
        setMsgErrorGenerate349([])
        setMsgErrorImport349([])
        setMsgErrorVies([])

        // SUCCESS
        setMsgSuccess([])
        setMsgSuccessImport349([])
        setMsgSuccessVies([])
        setMsgSuccessGenerate390([])
        setMsgSuccessGenerate349([])
        setMsgSuccessImport390([])

        // LOADING
        setLoading(false)
        setLoadingBtn(false)
        setLoadingBtnNoVerify(false)
        setLoadingBtnSend(false)
        setLoadingProgress(false)
    }

    const columns303 = useMemo(
        () => [
            {
                Header: "COD. CLIENTE",
                accessor: "organizationClientCode",
                width: "200px"
            },
            {
                Header: "NIF",
                accessor: "organizationNif",
                width: "200px"
            },
            {
                Header: "ORGANIZACION",
                accessor: "organizationName",
                width: "200px"
            },
            {
                Header: "ORGANIZACION CHINO",
                accessor: "organizationNameChinese",
                width: "200px"
            },
            {
                Header: "AÑO",
                accessor: "year",
                width: "100px",
                Filter: SelectColumnFilter,
            },
            {
                Header: "TRIMESTRE",
                accessor: "quarter",
                width: "100px",
                Filter: SelectColumnFilter,
            },
            {
                Header: "IVA TRIMESTRE ANTERIOR",
                accessor: "previousQuarter",
                width: "200px"
            },
        ],
        []
    )

    const columns349 = useMemo(
        () => [
            {
                Header: "COD. CLIENTE",
                accessor: "organizationClientCode",
                width: "200px"
            },
            {
                Header: "NIF",
                accessor: "organizationNif",
                width: "200px"
            },
            {
                Header: "NIF CONTRAPARTE",
                accessor: "counterpartVatNumber",
                width: "200px"
            },
            {
                Header: "IMPORTE",
                accessor: "import",
                width: "200px"
            },
            {
                Header: "TIPO",
                accessor: "typeFormated",
                width: "100px",
                Filter: SelectColumnFilter,
            },
            {
                Header: "NOMBRE CONTRAPARTE",
                accessor: "viesName",
                width: "100px",
                Filter: SelectColumnFilter,
            },
        ],
        []
    )


    const steps349 = [
        {
            label: 'Importación de operaciones intracomunitarias',
            description: `Subir fichero para poder procesar las operaciones intracomunitarias de las empresas.`,
        },
        {
            label: 'Validación de datos',
            description:
                'Comprobar datos y revisar que todo esté bien.',
        },
        {
            label: 'Validación por VIES',
            description:
                'Comprobar los NIF contra VIES.',
        },
        {
            label: 'Generación del modelo',
            description: `Generar el modelo y verificar datos.`,
        },
    ];

    const steps390 = [
        {
            label: 'Seleccionar las organizaciones para presentar el modelo',
            description: `Subir fichero para poder procesar las organizaciones a generar el modelo.`,
        },
        {
            label: 'Validación de datos',
            description: 'Comprobar datos y revisar que todo esté bien.',
        },
        {
            label: 'Generación del modelo',
            description: `Generar el modelo y verificar datos.`,
        }
    ];

    function setSalesData(snapshot) {
        const temp = snapshot.map((doc) => {
            const href = `/admin/sales/edit/${doc.id}`
            return {
                organizationName: doc?.organization?.name,
                organizationNif: (<Link color="inherit" target="_blank" href={href}>{doc?.organization?.nif}</Link>),
                organizationClientCode: doc?.organization?.clientCode,
                organizationNameChinese: doc?.organization?.nameChinese,
                ...doc
            }
        })
        setSales(temp)
    }

    function setSalesData349(snapshot) {
        const temp = snapshot.map((doc) => {
            const href = `/admin/sales/edit/${doc.id}`
            return {
                organizationNif: doc?.organization?.nif,
                organizationClientCode: doc?.organization?.clientCode,
                // Hay 9 tipos de operaciones: E, M, H, A, T, S, I, R y D
                typeFormated: doc?.type === "E" ? "Entrega" : doc?.type === "M" ? "M" : doc?.type === "H" ? "H" :
                    doc?.type === "A" ? "Adquisición" : doc?.type === "T" ? "T" : doc?.type === "S" ? "S" :
                        doc?.type === "I" ? "I" : doc?.type === "R" ? "R" : doc?.type === "D" ? "D" : "",
                ...doc
            }
        })
        setData349(temp)
    }

    const importSales = async () => {
        restartState()
        setLoadingProgress(true)
        let f = new FormData()
        for (const property in state) {
            if (property !== "file") {
                f.append(`${property}`, state[property])
            }

        }
        if (file) {
            f.append("file", file[0])
        }
        const response = await importSales349Api(logout, f).then(response => {
            showSalesModel()
            setLoadingProgress(false);
            setMsgSuccessImport349(response.data.msg)
        }).catch(error => {
            form.current.reset()
            setLoadingProgress(false);
            setMsgErrorImport349(error.response.data.errors)
        })
    }
    const getOrganizations390 = async () => {
        restartState()
        setLoadingProgress(true)
        let f = new FormData()
        for (const property in state) {
            if (property !== "file") {
                f.append(`${property}`, state[property])
            }
        }
        if (file) {
            f.append("file", file[0])
        }
        const response = await getOrganizations390Api(logout, f).then(response => {
            showSalesModel()
            setLoadingProgress(false);
            setMsgSuccessImport390(response.data.msg)
        }).catch(error => {
            form.current.reset()
            setLoadingProgress(false);
            setMsgErrorImport390(error?.response?.data?.errors)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
        if (event.target.name === "model") {
            switch (event.target.value) {
                case "349":
                    setShowButtonPreview(false)
                    setShowButton349(true)
                    setShowButton390(false)
                    setShowButton390(false)
                    break;
                case "303":
                    setShowButtonPreview(true)
                    setShowButton349(false)
                    setShowButton390(false)
                    setShowButton390(false)
                    break;
                case "390":
                    setShowButtonPreview(false)
                    setShowButton349(false)
                    setShowButton390(true)
                    break;
            }
        }
    }


    const handleChangeFile = (event) => {
        setFile(event.target.files)
        state[event.target.name] = true
        setState({...state})
    }

    const handleImportIntra = (event) => {
        event.preventDefault()

        setMsgErrorImport349([])
        let error = validationSchema(state, show349)
        if (error.length !== 0) {
            setMsgErrorImport349(error)
            return
        }

        void importSales()
    }
    const handleImportOrganizations = (event) => {
        event.preventDefault()

        setMsgErrorImport390([])
        let error = validationSchema(state, show390)
        if (error.length !== 0) {
            setMsgErrorImport390(error)
            return
        }

        void getOrganizations390()

    }

    const handleCheckVies = (event) => {
        event.preventDefault()
        restartState()
        setLoadingProgress(true)

        checkVatVies(logout, state).then(data => {
            if (data.status === true) {
                setMsgSuccessVies("NIFs contra VIES validados correctamente")
                setEnableNextVies(true)
                setLoadingProgress(false)

            }
        }).catch((errors) => {
            setMsgErrorVies(errors.response.data.errors)
            setLoadingProgress(false)
        })
    }
    const handleCheckWithoutVies = (event) => {
        event.preventDefault()

        setEnableNextVies(true)
        setMsgSuccessVies("NIFs validados correctamente")

    }
    const handleWizardStart = (event) => {
        event.preventDefault()
        restartState()

        let error = validationSchema(state)
        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        if (showTable303) {
            setShowTable303(false);
        }
        if (show390) {
            setShow390(false);
        }
        if (!show349) {
            setShow349(true);
        }
    }
    const handleWizard390Start = (event) => {
        event.preventDefault()
        restartState()

        let error = validationSchema(state)
        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        if (showTable303) {
            setShowTable303(false);
        }
        if (show349) {
            setShow349(false);
        }
        if (!show390) {
            setShow390(true);
        }

    }

    const handleShowModel = (event) => {
        event.preventDefault()
        restartState()

        setLoading(true)
        setSales(null)
        setPreview(false)

        setShowTable303(false)
        setShow349(false)
        setShow390(false)

        let error = validationSchema(state)
        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return
        }

        if (state.model === "303") {
            showSalesModel()
        }
    }

    const handleDownloadModel = async (event, verify) => {
        event.preventDefault()
        let idPro = null
        restartState()

        let error = validationSchema(state)
        if (error.length !== 0) {
            setMsgError(error)
            return
        }
        state["verify"] = verify
        let excludedIds = []
        for (const position in selectedRows) {
            excludedIds.push(sales[position].id)
        }
        state["excludedIds"] = excludedIds;
        void downloadModel(verify, idPro)
        setModalProcessing(false)
    }


    const handleSendModel = (event, verify) => {
        event.preventDefault()
        initProcessing(event, verify)
    }

    const showSalesModel = () => {
        showSalesModelApi(logout, state).then(data => {
            setLoading(false)
            if (data.data.preview.length > 0) {
                if (state.model === "303") {
                    setSalesData(data.data.preview)
                    setPreview(true)
                    setShowTable303(true)
                } else if (state.model === "349") {
                    setShow349(true)
                    setSalesData349(data.data.preview)
                    setPreview(true)
                    setEnableNextImport349(true)
                } else if (state.model === "390") {
                    setSalesData(data.data.preview)
                    setPreview(true)
                    setEnableNextImport390(true)
                }
            } else {
                setMsgError(["No hay ventas para mostrar de este año y/o trimestre"])
            }
        }).catch((errors) => {
            setMsgError([errors.response.data.errors])
            setLoading(false)
        })
    }

    const downloadModel = async (verify) => {
        if (preview) {
            if (verify) {
                setLoadingBtn(true)
            } else {
                setLoadingBtnNoVerify(true)
            }

            downloadModelApi(logout, state).then((response) => {
                setIdProcessing(null)
                setLoadingBtn(false)
                setLoadingBtnNoVerify(false)
                const url = window.URL.createObjectURL(new Blob([response]));

                const link = document.createElement('a');

                link.href = url;
                const d = new Date()
                const date = d.getFullYear().toString() + (d.getMonth() + 1).toString() + d.getDate().toString() + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
                link.setAttribute('download', 'modelo-' + state.model + "-" + date + '.zip'); //or any other extension

                document.body.appendChild(link);

                link.click();

                if (state["verify"]) {
                    setSales(null)
                    setPreview(false);
                }
            }).catch((errors) => {
                setLoadingBtn(false)
                setLoadingBtnNoVerify(false)
                setMsgError(['Ha ocurrido un error al descargar el modelo, por favor intente nuevamente o contacte a soporte.'])
            })

        } else {
            setMsgError(["Por favor, asegúrate de que todos los datos son correctos antes de generar el modelo"])
        }
    }
    const initProcessing = (event, verify) => {
        setProcessingSalesApi().then((resp) => {
            let idPro = resp.id
            setIdProcessing(idPro)

            // Procesar solicitud
            restartState()

            let error = validationSchema(state)
            if (error.length !== 0) {
                setMsgError(error)
                return
            }
            state["verify"] = verify
            let excludedIds = []
            for (const position in selectedRows) {
                excludedIds.push(sales[position].id)
            }
            state["excludedIds"] = excludedIds;
            setLoadingBtnSend(true)
            if(file){
                state["file"] = file[0]
            }

            void sendModel(verify, idPro)
            // End procesar solicitud

        })
    }

    useEffect(() => {
        if (idProcessing) {
            setModalProcessing(true)
            const interval = setInterval(() => {
                setTime(!time)
                // Consultar en base de datos el valor del procesamiento
                getProcessingSalesApi(idProcessing).then((resp) => {
                    setSolicitudPorcentaje({
                        value: parseInt(resp.data.value),
                        total: resp.data.total,
                        processed: resp.data.processed,
                    })
                })

            }, 5000)
            return () => clearInterval(interval)
        } else {
            setModalProcessing(false)
        }
    }, [time, idProcessing])

    const sendModel = (verify, idPro) => {
        setMsgSuccess([])
        setMsgError([])
        if (preview) {
            if(state.file){
                state["file"] = null
            }

            const params = clone(state)
            params.idProcessing = idPro
            sendModelApi(params).then((response) => {
                setLoadingBtnSend(false)
                setSales(null)
                setPreview(false);
                setMsgSuccess(response.data.msg)
                setIdProcessing(null)

                //download zip from response
                if (response.data.url) {
                    let url = response.data.url
                    let name = url.split('/').pop()
                    downloaderFile(url, name)
                }
            }).catch((errors) => {
                setIdProcessing(null)
                setLoadingBtn(false)
                setLoadingBtnNoVerify(false)
                setLoadingBtnSend(false)
                setIdProcessing(null)
                if (errors?.response.data.url) {
                    let url = errors?.response.data.url
                    let name = url.split('/').pop()
                    downloaderFile(url, name)
                }
                setMsgError(errors?.response.data.msg)
            })
        } else {
            setMsgError(["Por favor, asegúrate de que todos los datos son correctos antes de generar el modelo"])
        }
    }
    const downloadTemplate = () => {
        window.open("/import_ventas_349.xlsx", '_blank');
    }

    const downloadTemplate390 = () => {
        window.open("/import_clientes_390.xlsx", '_blank');
    }


    return (
        <>

            <ModalProcessing
                open={modalProcessing}
                setOpen={setModalProcessing}
                progress={solicitudPorcentaje}
            />

            <Grid item xs={12}>
                <h1>Generación de Modelos</h1>
            </Grid>
            <Grid item xs={3}>
                <InputSelect
                    label="Modelo"
                    name="model"
                    required={true}
                    options={optionsModel}
                    value={state?.model}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={3}>
                <InputSelect
                    label="Año Ventas"
                    name="year"
                    required={true}
                    options={optionsSaleYear}
                    value={state?.year}
                    onChange={handleChange}
                />
            </Grid>
            {state.model != "390" ?
                <Grid item xs={3}>
                    <InputSelect
                        label="Trimestre Ventas"
                        name="quarter"
                        required={true}
                        options={optionsSaleQuarter}
                        value={state?.quarter}
                        onChange={handleChange}
                    />
                </Grid> : null}


            {showButtonPreview && (
                <Grid item xs={3}>
                    <LoadingButton
                        type="button"
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        onClick={handleShowModel}
                        style={{width: 200}}
                    >
                        Mostrar Datos
                    </LoadingButton>
                </Grid>
            )}

            {showButton349 && (
                <Grid item xs={3}>
                    <Button
                        type="button"
                        onClick={handleWizardStart}
                        variant="contained"
                        color="primary"
                    >
                        Iniciar Generación
                    </Button>
                </Grid>
            )}
            {showButton390 && (
                <Grid item xs={3}>
                    <Button
                        type="button"
                        onClick={handleWizard390Start}
                        variant="contained"
                        color="primary"
                    >
                        Iniciar Generación
                    </Button>
                </Grid>
            )}

            <MsgSuccess msgSuccess={msgSuccess}/>
            <MsgError msgError={msgError}/>

            {showTable303 ? [
                <Grid item xs={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <TableFilter
                            columns={columns303}
                            data={sales ? sales : []}
                            search={false}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            selectable={true}
                            selectTitle="EXCLUIR REGISTROS"
                        />
                    </Paper>
                </Grid>,

                <Grid item md={3}>
                    <LoadingButton
                        type="button"
                        loading={loadingBtnNoVerify}
                        loadingPosition="start"
                        variant="outlined"
                        fullWidth
                        onClick={(e) => handleDownloadModel(e, false)}
                    >
                        Generar modelo sin verificar
                    </LoadingButton>
                </Grid>,

                <Grid item md={3}>
                    <LoadingButton
                        type="button"
                        loading={loadingBtn}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                        onClick={(e) => handleDownloadModel(e, true)}
                    >
                        Verificar y generar modelo
                    </LoadingButton>
                </Grid>,
                <Grid item md={3}>
                    <LoadingButton
                        type="button"
                        loading={loadingBtnSend}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                        onClick={(e) => handleSendModel(e, true)}
                    >
                        Verificar y presentar modelo a AEAT
                    </LoadingButton>
                </Grid>,
            ] : null}

            {show349 ? [
                <VerticalWizard
                    steps={steps349}
                    stepsEnableNext={[!enableNextImport349, false, !enableNextVies, !enableFinish]}
                    stepsContent={[
                        [
                            <Grid xs={12}>
                                <Grid item xs={12}>
                                    <MsgSuccess msgSuccess={msgSuccessImport349}/>
                                    <MsgError msgError={msgErrorImport349}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <form ref={form}>
                                        <InputFile
                                            label="Importar Operaciones Intracoumintarias"
                                            name="file"
                                            onChange={handleChangeFile}
                                        />
                                    </form>
                                </Grid>
                                &nbsp;
                                <Grid item xs={6}>
                                    <Button
                                        type="button"
                                        onClick={downloadTemplate}
                                        variant="outlined"
                                    >
                                        Descargar plantilla
                                    </Button>
                                </Grid>
                                &nbsp;
                                <Grid item xs={6}>
                                    <Button
                                        type="button"
                                        onClick={handleImportIntra}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Importar Operaciones Intracomunitarias
                                    </Button>
                                    <LoadingProgress show={loadingProgress}/>
                                </Grid>
                            </Grid>,
                        ],
                        [
                            <Grid item xs={12}>
                                <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                                    <TableFilter
                                        columns={columns349}
                                        data={data349 ? data349 : []}
                                        search={false}
                                        selectedRows={selectedRows}
                                        onSelectedRowsChange={setSelectedRows}
                                        selectable={false}
                                    />
                                </Paper>
                            </Grid>,
                        ],
                        [
                            <Grid xs={12}>
                                <Grid item xs={12}>
                                    <MsgSuccess msgSuccess={msgSuccessVies}/>
                                    <MsgError msgError={msgErrorVies}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        type="button"
                                        onClick={handleCheckVies}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Comprobar contra VIES
                                    </Button>
                                    <LoadingProgress show={loadingProgress}/>
                                </Grid>
                                &nbsp;
                                <Grid item xs={6}>
                                    <Button
                                        type="button"
                                        onClick={handleCheckWithoutVies}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Validar sin comprobar contra VIES
                                    </Button>
                                </Grid>
                            </Grid>,
                        ],
                        [
                            <Grid xs={12}>
                                <Grid item xs={12}>
                                    <MsgSuccess msgSuccess={msgSuccessGenerate349}/>
                                    <MsgError msgError={msgErrorGenerate349}/>
                                </Grid>
                                <Grid xs={4}>
                                    <LoadingButton
                                        type="button"
                                        loading={loadingBtnNoVerify}
                                        loadingPosition="start"
                                        variant="outlined"
                                        fullWidth
                                        onClick={(e) => handleDownloadModel(e, false)}
                                    >
                                        Generar modelo sin verificar
                                    </LoadingButton>
                                </Grid>
                                &nbsp;
                                <Grid xs={4}>
                                    <LoadingButton
                                        type="button"
                                        loading={loadingBtn}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                        onClick={(e) => handleDownloadModel(e, true)}
                                    >
                                        Verificar y generar modelo
                                    </LoadingButton>
                                </Grid>
                                &nbsp;
                                <Grid item md={4}>
                                    <LoadingButton
                                        type="button"
                                        loading={loadingBtnSend}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                        onClick={(e) => handleSendModel(e, true)}
                                    >
                                        Verificar y presentar modelo a AEAT
                                        {solicitudPorcentaje > 0 ? <span> {solicitudPorcentaje}%</span> : null}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        ],
                    ]}
                />
            ] : null}
            {show390 ? [
                <VerticalWizard
                    steps={steps390}
                    stepsEnableNext={[!enableNextImport390, false, !enableFinish]}
                    stepsContent={[
                        [
                            <Grid xs={12}>
                                <Grid item xs={12}>
                                    <MsgSuccess msgSuccess={msgSuccessImport390}/>
                                    <MsgError msgError={msgErrorImport390}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <form ref={form}>
                                        <InputFile
                                            label="Importar Fichero"
                                            name="file"
                                            onChange={handleChangeFile}
                                        />
                                    </form>
                                </Grid>
                                &nbsp;

                                <Grid item xs={6}>
                                    <Button
                                        type="button"
                                        onClick={downloadTemplate390}
                                        variant="outlined"
                                    >
                                        Descargar plantilla
                                    </Button>
                                    &nbsp;

                                    <Button
                                        type="button"
                                        onClick={handleImportOrganizations}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Importar Fichero
                                    </Button>
                                    <LoadingProgress show={loadingProgress}/>
                                </Grid>
                            </Grid>,
                        ],
                        [
                            <Grid item xs={12}>
                                <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                                    <TableFilter
                                        columns={columns303}
                                        data={sales ? sales : []}
                                        search={false}
                                        selectedRows={selectedRows}
                                        onSelectedRowsChange={setSelectedRows}
                                        selectable={false}
                                    />
                                </Paper>
                            </Grid>,
                        ],
                        [
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <MsgSuccess msgSuccess={msgSuccessGenerate390}/>
                                    <MsgError msgError={msgErrorGenerate390}/>
                                </Grid>

                                <Grid item md={3}>
                                    <LoadingButton
                                        type="button"
                                        loading={loadingBtnNoVerify}
                                        loadingPosition="start"
                                        variant="outlined"
                                        fullWidth
                                        onClick={(e) => handleDownloadModel(e, false)}
                                    >
                                        Generar modelo sin verificar
                                    </LoadingButton>
                                </Grid>

                                <Grid item md={3}>
                                    <LoadingButton
                                        type="button"
                                        loading={loadingBtn}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                        onClick={(e) => handleDownloadModel(e, true)}
                                    >
                                        Verificar y generar modelo
                                    </LoadingButton>
                                </Grid>

                                <Grid item md={3}>
                                    <LoadingButton
                                        type="button"
                                        loading={loadingBtnSend}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                        onClick={(e) => handleSendModel(e, true)}
                                    >
                                        Verificar y presentar modelo a AEAT
                                        {solicitudPorcentaje > 0 ? <span> {solicitudPorcentaje}%</span> : null}

                                    </LoadingButton>
                                </Grid>,
                            </Grid>
                        ],

                    ]}
                />
            ] : null}

        </>
    );
}

function initialValues() {
    return {
        model: "",
        year: "",
        quarter: "",
        mode: "",
        verificar: false,
    }
}

function validationSchema(schema, show349 = false) {

    let error = []

    if (!schema.model) {
        error.push("El modelo es obligatorio")
    } else {
        if (schema.model === "349" && !schema.file && show349) {
            error.push("Por favor, selecciona un fichero a importar")
        }
    }
    if (!schema.year) {
        error.push("El año de ventas es obligatorio")
    }
    if (!schema.quarter && schema.model !== "390") {
        error.push("El trimestre es obligatorio")
    }


    return error
}

import React from "react";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import "./scss/global.scss"

// AUTH
import Login from "./pages/Auth/login"
import Logout from "./pages/Auth/logout"
import Register from "./pages/Auth/register"
import ForgottenPassword from "./pages/Auth/forgottenPassword"
import RecoverPassword from "./pages/Auth/recoverPassword"

// ADMIN
import AdminDashboardManagement from "./pages/Admin/Dashboard/management"
import AdminUserManagement from "./pages/Admin/User/management"
import AdminUserEdit from "./pages/Admin/User/edit"
import AdminUserCreate from "./pages/Admin/User/create"
import AdminOrganizationManagement from "./pages/Admin/Organization/management"
import AdminOrganizationEdit from "./pages/Admin/Organization/edit"
import AdminOrganizationCreate from "./pages/Admin/Organization/create"
import AdminOrganizationImport from "./pages/Admin/Organization/import"
import AdminEpigrafeManagement from "./pages/Admin/Epigrafe/management"
import AdminEpigrafeEdit from "./pages/Admin/Epigrafe/edit"
import AdminEpigrafeCreate from "./pages/Admin/Epigrafe/create"
import AdminSaleManagement from "./pages/Admin/Sale/management"
import AdminSaleEdit from "./pages/Admin/Sale/edit"
import AdminSaleCreate from "./pages/Admin/Sale/create"
import AdminSale349Management from "./pages/Admin/Sale349/management"
import AdminSale349Edit from "./pages/Admin/Sale349/edit"
import AdminSale349Create from "./pages/Admin/Sale349/create"
import AdminSale390Management from "./pages/Admin/Sale390/management"
import AdminSale390Edit from "./pages/Admin/Sale390/edit"
import AdminSale390Create from "./pages/Admin/Sale390/create"
import AdminSaleImport from "./pages/Admin/Sale/import"
import AdminModelGenerate from "./pages/Admin/Model/generate"


// USER
import Homepage from "./pages/User/homepage"

import Auth from "./auth"



export default function App() {

    return (
        <Router>
            <Auth>
                <Switch>

                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/login" render={Login}/>
                    <Route exact path="/logout" render={Logout}/>
                    <Route exact path="/register" render={Register}/>
                    <Route exact path="/forgot-password" render={ForgottenPassword}/>
                    <Route exact path="/recover-password/:token" render={RecoverPassword}/>

                    <Route exact path="/admin/users/management" render={(props) => <AdminUserManagement roles={["ROLE_ADMINISTRATOR"]}/>}/>
                    <Route exact path="/admin/users/edit/:id" render={(props) => <AdminUserEdit roles={["ROLE_ADMINISTRATOR"]}/>}/>
                    <Route exact path="/admin/users/create" render={(props) => <AdminUserCreate roles={["ROLE_ADMINISTRATOR"]}/>}/>

                    <Route exact path="/admin/dashboard/management" render={(props) => <AdminDashboardManagement roles={["ROLE_ADMINISTRATOR"]}/>}/>

                    <Route exact path="/admin/organizations/management" render={(props) => <AdminOrganizationManagement roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/organizations/edit/:id" render={(props) => <AdminOrganizationEdit roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/organizations/create" render={(props) => <AdminOrganizationCreate roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/organizations/import" render={(props) => <AdminOrganizationImport roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>

                    <Route exact path="/admin/epigrafes/management" render={(props) => <AdminEpigrafeManagement roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/epigrafes/edit/:id" render={(props) => <AdminEpigrafeEdit roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/epigrafes/create" render={(props) => <AdminEpigrafeCreate roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>

                    <Route exact path="/admin/sales/management" render={(props) => <AdminSaleManagement roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales/edit/:id" render={(props) => <AdminSaleEdit roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales/create" render={(props) => <AdminSaleCreate roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales/import" render={(props) => <AdminSaleImport roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales349/management" render={(props) => <AdminSale349Management roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales349/edit/:id" render={(props) => <AdminSale349Edit roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales349/create" render={(props) => <AdminSale349Create roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales390/management" render={(props) => <AdminSale390Management roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales390/edit/:id" render={(props) => <AdminSale390Edit roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>
                    <Route exact path="/admin/sales390/create" render={(props) => <AdminSale390Create roles={["ROLE_ADMINISTRATOR", "ROLE_GESTOR"]}/>}/>

                    <Route exact path="/admin/models/generate" render={(props) => <AdminModelGenerate roles={["ROLE_ADMIN", "ROLE_GESTOR"]}/>}/>
                </Switch>
            </Auth>
        </Router>
    );
}
